.container {
  width: 650px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: var(--item-padding-top) var(--item-padding-left);

  footer {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: end;

    padding-right: calc(var(--item-padding-left) * 0.75);
  }
}
