.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: calc(var(--gap) * 3);

  width: clamp(700px, 80vw, 800px);
}

.startTip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: calc(var(--gap));
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}

.skip__container {
  width: 100%;
  display: flex;

  .skip {
    margin-left: auto;
  }
}
