:root {
  --main-color: #246aed;
  --main-second-color: #246aed1f;
  --main-background-color: #fefefe;
  --second-background-color: #0000000a;
  --black: #000000;
  --green: #05fa05;
  --exclude: #ef7777;

  --disable-color: hsla(0, 0%, 0%, 0.04);

  --transparent-color: rgba(102, 118, 133, 0.4);

  //FONT
  --font-color: #000000;
  --second-font-color: #00000083;
  
  --box-shadow: rgba(17, 12, 46, 0.25) 0px 48px 100px 0px;

  --error-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 0, 1) 0%,
    rgba(238, 241, 244, 1) 0%,
    rgba(238, 241, 244, 1) 0%,
    rgba(234, 57, 67, 0.2) 100%
  );

  --error-gradient-hover: linear-gradient(
    180deg,
    rgba(255, 255, 0, 1) 0%,
    rgba(238, 241, 244, 1) 0%,
    rgba(238, 241, 244, 1) 0%,
    rgba(234, 57, 67, 0.4) 100%
  );

  --green-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 0, 1) 0%,
    rgba(238, 241, 244, 1) 0%,
    rgba(238, 241, 244, 1) 0%,
    rgba(22, 199, 132, 0.2) 100%
  );

  --green-gradient-hover: linear-gradient(
    180deg,
    rgba(255, 255, 0, 1) 0%,
    rgba(238, 241, 244, 1) 0%,
    rgba(238, 241, 244, 1) 0%,
    rgba(22, 199, 132, 0.4) 100%
  );

  --blue-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 0, 1) 0%,
    rgba(238, 241, 244, 1) 0%,
    rgba(238, 241, 244, 1) 0%,
    rgba(0, 91, 255, 0.3) 100%
  );

  --scroll-color: #005bff;

  --border-color: #c7cbce;
  --border-focus-color: #e8ebef;

  --second-border-color: #e8ebef;

  --focus-color: #eff1f4;

  --skeleton: linear-gradient(-45deg, #e2e4e8, var(--second-background-color), #e2e4e8, var(--second-background-color));

  //DEFAULT
  --transition: 0.3s;
  --input-border-radius: 5px;
  --border-radius: 30px;

  --item-padding-top: 18px;
  --item-padding-left: 18px;

  --footer-padding-top: 20px;

  --page-padding-top: calc(var(--header-height) + 40px);
  --page-padding-left: 80px;
  --gap: 8px;

  --page-gap: 30px;
  --page-items-padding: 15px;

  --table-height: 75px;

  //FONT
  --font-xxl: 24px;
  --font-xl: 20px;
  --font-size: 15px;
  --font-s: 13px;
  --font-xs: 10px;

  //SCROLLBAR
  --scrollbar-width: 5px;
  --scrollbar-height: 5px;
  --scrollbar-radius: 10px;

  --buttonScroll-height: 30px;
  --buttonScroll-width: 30px;

  //HEADER
  --header-height: 70px;

  //ICONS
  --svg-width: 24px;
}
